<template>
  <div class="wrapper">
    <Crumb :title.sync="title" :menu.sync="menu" />
    <Banner :img.sync="img" />
    <div class="container">
      <div class="content">
        <div class="message">
          <dl>
            <dt>
              <em>*</em>
              <label for="reason">留言原因</label>
            </dt>
            <dd>
              <div class="border">
                <select name="reason" v-model="message.reason">
                  <option>请选择</option>
                  <option value="咨询">咨询</option>
                  <option value="投诉">投诉</option>
                  <option value="建议">建议</option>
                  <option value="其它">其它</option>
                </select>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <em>*</em>
              <label for="name">姓名</label>
            </dt>
            <dd>
              <div class="border">
                <input type="text" name="name" v-model="message.name" />
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <label for="org">工作单位</label>
            </dt>
            <dd>
              <div class="border">
                <input type="text" name="org" v-model="message.org" />
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <em>*</em>
              <label for="email">电子邮件</label>
            </dt>
            <dd>
              <div class="border">
                <input type="text" name="email" v-model="message.email" />
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <em>*</em>
              <label for="area">所在地区</label>
            </dt>
            <dd>
              <div class="border">
                <input type="text" name="area" v-model="message.area" />
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <em>*</em>
              <label for="phone">联系电话</label>
            </dt>
            <dd>
              <div class="border">
                <input type="text" name="phone" v-model="message.phone" />
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <em>*</em>
              <label for="subject">主题</label>
            </dt>
            <dd>
              <div class="border">
                <input type="text" name="subject" v-model="message.subject" />
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <em>*</em>
              <label for="content">信息内容</label>
            </dt>
            <dd>
              <div class="border">
                <textarea
                  type="text"
                  name="content"
                  rows="4"
                  v-model="message.content"
                ></textarea>
              </div>
            </dd>
          </dl>
          <div class="button">
            <button @click="submitMessage()">提交</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Crumb from "@/components/Crumb.vue";
import Banner from "@/components/Banner.vue";
// import qs from "qs";
export default {
  components: { Crumb, Banner },
  data() {
    return {
      title: "",
      menu: [],
      img: require("@/assets/images/banner1.jpg"),
      message: {
        reason: "",
        name: "",
        org: "",
        email: "",
        area: "",
        phone: "",
        subject: "",
        content: "",
      },
    };
  },
  methods: {
    submitMessage() {
      if (this.message.reason.length == 0) {
        alert("请选择原因");
        return;
      }
      if (this.message.name.length == 0) {
        alert("请输入姓名");
        return;
      }
      if (this.message.email.length == 0) {
        alert("请输入电子邮件");
        return;
      }
      if (this.message.area.length == 0) {
        alert("请输入所在地区");
        return;
      }
      if (this.message.phone.length == 0) {
        alert("请输入联系电话");
        return;
      }
      if (this.message.subject.length == 0) {
        alert("请输入主题");
        return;
      }
      if (this.message.content.length == 0) {
        alert("请输入信息内容");
        return;
      }
      this.axios
        .post(
          "https://s.icloudportal.com/api/submitWebsiteContactForm",
          JSON.stringify({
            site: "m3FntHT5iWaBQqcGW",
            type: "feedback",
            values: this.message,
          }),
          { headers: { "Content-Type": "application/json; charset=utf-8;" } }
        )
        .then((response) => {
          alert("提交成功！");
          console.log("res:", response);
          this.message = {};
        })
        .catch((error) => {
          console.log("err:", error);
        });
    },
  },
};
</script>

<style scoped lang="less">
.wrapper {
  .container {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    padding: 30px 0;
    .title {
      text-align: center;
      margin-bottom: 30px;
      border-bottom: 1px solid #ddd;
      h1 {
        font-size: 28px;
        line-height: 60px;
        font-weight: normal;
        text-align: center;
      }
      p {
        font-size: 14px;
        color: #999;
        margin-bottom: 20px;
        span {
          margin: 0 20px;
        }
      }
    }
    .content {
      .message {
        width: 600px;
        margin: 0 auto;
        padding: 60px 0;
        dl {
          margin-bottom: 20px;
          clear: both;
          dt {
            float: left;
            width: 90px;
            text-align: right;
            font-size: 14px;
            line-height: 36px;
            padding-right: 10px;
            em {
              font-style: normal;
              color: #ff0000;
              margin-right: 5px;
            }
            label {
              font-family: "Microsoft YaHei";
              font-size: 14px;
            }
          }
          dd {
            margin-left: 100px;
            .border {
              line-height: 22px;
              padding: 6px 0 6px 8px;
              border: 1px solid #ddd;
            }
            select,
            input,
            textarea {
              width: 100%;
              height: 22px;
              font-family: "Microsoft YaHei";
              font-size: 14px;
              line-height: 22px;
              color: #000;
              margin: 0;
              padding: 0;
              border: none;
              outline: none;
            }
            textarea {
              height: auto;
            }
          }
        }
        .button {
          text-align: center;
          margin-top: 40px;
          button {
            background: #cf281a;
            height: 40px;
            font-size: 14px;
            color: #fff;
            padding: 0 50px;
            border: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
